import i18n from '@/i18n'
import SelectorDialog from '@/components/commons/selector/dialog/SelectorDialog.vue'
import { filterRulesMapped } from '@/tools/filterRules'
import reminderApi from '@/api/reminder.api'
import { mapActions, mapMutations } from 'vuex'
import { DialogType, SnackbarStyle } from '@/constants/constants'
import ListTreeViewComponent from '@/components/commons/list/tree-view/ListTreeViewComponent.vue'
import { privilegesService } from '@/business/privilegesService'
import ReminderDialogComponent from './dialog/ReminderABMDialog.vue'
import enterpriseApi from '@/api/enterprise.api'

export default {
  name: 'ReminderABMComponent',
  components: {
    ListTreeViewComponent,
    SelectorDialog,
    ReminderDialogComponent
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    rowActions: [
      {
        id: 1,
        name: i18n.t('modify'),
        color: 'blue',
        icon: 'fa-pen'
      },
      {
        id: 2,
        name: i18n.t('delete'),
        color: 'red',
        icon: 'fa-trash-alt'
      }
    ],
    tableComponentButtons: [],
    pageSize: undefined,
    pageNumber: undefined,
    pageTotal: undefined,
    loadingTable: false,
    enterprises: [],
    showABMDialog: false,
    edit: false,
    editDto: null
  }),
  computed: {
  },
  async created () {
    // Botón de eliminación múltiple
    this.tableComponentButtons.push(
      {
        id: 'edit-dialog',
        tooltip: i18n.t('add'),
        icon: 'add',
        action: this.showDialog.bind(this),
        selectedData: false
      },
      {
        id: 'masive-delete',
        tooltip: i18n.t('reminder.type.multipleDelete'),
        icon: 'delete_outline',
        action: this.multipleDelete.bind(this),
        selectedData: true
      })
    await this.getEnterprises()
    this.resetFields()
    this.getData()
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    /**
     * Refrescar la data
     */
    refreshTable () {
      this.getData(true)
    },
    async getEnterprises () {
      if (!privilegesService.validatePrivilegesAnd(['configurations.callCenter'])) { // chequear que no sea del call-center
        const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))
        this.enterprises = (await enterpriseApi.getMultiple([enterpriseId])).data
      } else {
        this.enterprises = (await enterpriseApi.getAll()).data
      }
    },
    async getData (refresh = false) {
      this.loadingTable = true
      if (!refresh) {
        this.pageSize = 50
        this.pageNumber = 1
      }
      if (!privilegesService.validatePrivilegesAnd(['configurations.callCenter'])) {
        this.pageTotal = (await reminderApi.getTotalCountForUser()).data
        reminderApi.getPaginatedForUser(this.pageSize, this.pageNumber).then(response => {
          this.tableData.cleanAndUpdate(response.data.data)
          if (!refresh) {
            this.tableHeaders.cleanAndUpdate(response.data.headers)
            this.setTableFilterRules()
          }
          this.loadingTable = false
        })
      } else {
        this.pageTotal = (await reminderApi.getTotalCount()).data
        reminderApi.getPaginated(this.pageSize, this.pageNumber).then(response => {
          this.tableData.cleanAndUpdate(response.data.data)
          if (!refresh) {
            this.tableHeaders.cleanAndUpdate(response.data.headers)
            this.setTableFilterRules()
          }
          this.loadingTable = false
        })
      }
    },
    setTableFilterRules () {
      this.tableFilterRules = {}
      this.tableHeaders.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    async delete (data) {
      await reminderApi.delete(data._id)
      this.getData(true)
    },
    async multipleDelete (data) {
      this.openDialog({
        title: this.$t('delete'),
        text: this.$t('reminder.multipleDeleteMessage'),
        type: DialogType.QUESTION,
        overlayOpacity: 0.5,
        actionButtons: true,
        yesAction: async () => {
          const ids = data.map(e => e._id)
          await reminderApi.multipleDelete(ids)
          this.getData(true)
          this.$refs['reminder-table'].resetSelected()
        },
        noAction: () => { }
      })
    },
    rowButtonClicked (button, data) {
      switch (button.id) {
        case 1:
          this.showABMDialog = true
          this.editDto = Object.assign({}, data)
          this.edit = true
          // editar
          break
        case 2:
          this.openDialog({
            title: this.$t('delete'),
            text: this.$t('reminder.deleteMessage'),
            type: DialogType.QUESTION,
            overlayOpacity: 0.5,
            actionButtons: true,
            yesAction: () => { this.delete(data) },
            noAction: () => { }
          })
          break
        default:
          console.log('default')
      }
    },
    showDialog () {
      this.showABMDialog = true
      this.edit = false
    },
    externalPaginator (pageSize, pageNumber) {
      this.pageNumber = Number(pageNumber)
      this.pageSize = Number(pageSize)
    },
    closeDialog () {
      this.showABMDialog = false
      this.resetFields()
    },
    async saveDialog (remindersInfo, singleSave = false) {
      if (this.edit) {
        this.showABMDialog = false
        await reminderApi.update(remindersInfo).then(() => {
          this.showSnackbar({ visible: true, text: this.$t('reminder.updateSuccess'), timeout: 9500, style: SnackbarStyle.SUCCESS })
          this.resetFields()
          this.getData(true)
        })
      } else if (singleSave) {
        await reminderApi.createMultiple(remindersInfo).then(() => {
          this.showSnackbar({ visible: true, text: this.$t('reminder.saveSuccess'), timeout: 9500, style: SnackbarStyle.SUCCESS })
          this.getData(true)
        })
      } else {
        this.showABMDialog = false
        await reminderApi.createMultiple(remindersInfo).then(() => {
          this.showSnackbar({ visible: true, text: this.$t('reminder.saveMultipleSuccess'), timeout: 9500, style: SnackbarStyle.SUCCESS })
          this.getData(true)
        })
      }
    },
    resetFields () {
      this.edit = false
      this.editDto = null
    }
  }
}
