import { mapActions } from 'vuex'
import { SnackbarStyle } from '@/constants/constants'

export default {
  name: 'ReminderEditableTable',
  components: {
  },
  props: {
    reminders: Array,
    expirationTypes: Array
  },
  data: () => ({
    headers: [],
    validForm: false,
    validationRules: {
      required: (value) => !!value || 'required',
      noNegative: (value) => parseFloat(value) >= 0 || 'min'
    }
  }),
  computed: {
  },
  created () {
    this.headers = []
    this.headers.push({ text: '', value: 'actions', width: '75px' })
    this.headers.push({ text: this.$t('reminder.object'), value: 'object.name', width: '150px' })
    this.headers.push({ text: this.$t('reminder.reminderType'), value: 'reminderTypeName', width: '150px' })
    if (this.expirationTypes.includes('hours') || this.expirationTypes.includes('km')) this.headers.push({ text: this.$t('reminder.field'), value: 'field', width: '200px' })
    if (this.expirationTypes.includes('hours') || this.expirationTypes.includes('km')) this.headers.push({ text: this.$t('reminder.currentValue'), value: 'currentValue', width: '150px' })
    if (this.expirationTypes.includes('hours') || this.expirationTypes.includes('km')) this.headers.push({ text: this.$t('reminder.startCurrent'), value: 'startCurrent', width: '100px' })
    if (this.expirationTypes.includes('hours') || this.expirationTypes.includes('km')) this.headers.push({ text: this.$t('reminder.start'), value: 'start', width: '120px' })
    if (this.expirationTypes.includes('date')) this.headers.push({ text: this.$t('reminder.expirationDate'), value: 'expirationDate', width: '200px' })
    this.headers.push({ text: this.$t('reminder.alertBefore'), value: 'alertBefore', width: '120px' })
    if (this.expirationTypes.includes('hours') || this.expirationTypes.includes('km')) this.headers.push({ text: this.$t('reminder.repeatEvery'), value: 'repeatEvery', width: '120px' })
    this.headers.push({ text: this.$t('reminder.automatic'), value: 'automatic', width: '100px' })
    if (this.expirationTypes.includes('hours') || this.expirationTypes.includes('km')) this.headers.push({ text: this.$t('reminder.note'), value: 'note', width: '250px' })
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),

    deleteItem (item) {
      const index = this.reminders.indexOf(item)
      this.reminders.splice(index, 1)
    },

    changeField (expiration) {
      expiration.selectedField = expiration.values.find(v => v.id === expiration.selectedField)
      expiration.start = expiration.startCurrent ? parseFloat(expiration.selectedField.lastValue) : expiration.start
    },

    changeCurrentStart (expiration) {
      expiration.start = expiration.startCurrent ? parseFloat(expiration.selectedField.lastValue) : undefined
    },

    saveItem (item) {
      if (this.validItem(item)) {
        const index = this.reminders.indexOf(item)
        this.reminders.splice(index, 1)
        this.$emit('saveItem', item)
      } else {
        this.showSnackbar({ visible: true, text: this.$t('reminder.missingData'), timeout: 9500, style: SnackbarStyle.ERROR })
      }
    },

    validItem (item) {
      let valid = true
      item.expirations.forEach(e => {
        if (e.type === 'date' && (!e.alertBefore || !e.expirationDate)) {
          valid = false
        }
        if ((e.type === 'hours' || e.type === 'km') && (!e.repeatEvery || !e.alertBefore || !e.start)) {
          valid = false
        }
      })
      return valid
    }
  },
  watch: {
    validForm () {
      this.$emit('changeValidForm', this.validForm)
    }
  }
}
