import { render, staticRenderFns } from "./ReminderABMDialog.vue?vue&type=template&id=612d6fca&scoped=true"
import script from "./ReminderABMDialog.js?vue&type=script&lang=js&external"
export * from "./ReminderABMDialog.js?vue&type=script&lang=js&external"
import style0 from "./ReminderABMDialog.vue?vue&type=style&index=0&id=612d6fca&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "612d6fca",
  null
  
)

export default component.exports