import i18n from '@/i18n'
import ListTreeViewComponent from '@/components/commons/list/tree-view/ListTreeViewComponent.vue'
import SelectorTree from '@/components/commons/selector/dialog/tree/SelectorTree.vue'
import ReminderEditableTable from './editable-table/ReminderEditableTable.vue'
import reminderApi from '@/api/reminder.api'
import thingApi from '@/api/thing.api'
import personApi from '@/api/person.api'
import { getChildrenFromList } from '@/tools/functions'

export default {
  name: 'ReminderDialogComponent',
  components: {
    ListTreeViewComponent,
    SelectorTree,
    ReminderEditableTable
  },
  props: {
    showDialog: Boolean,
    edit: Boolean,
    enterprises: Array,
    editDto: Object
  },
  data: () => ({
    validForm: null,
    alertValidForm: false,
    alertValidationRules: {
      emailsRequired: (alertEmail, alertEmails) => !alertEmail || (alertEmail && !!alertEmails) || `${i18n.t('reminder.alert.emailsRequired')}`,
      alertRequired: (alertEmail, alertPush) => (alertEmail || alertPush) || `${i18n.t('reminder.alert.required')}`
    },
    editValidForm: false,
    editValidationRules: {
      required: (value) => !!value || value === 0 || `${i18n.t('required')}`,
      noNegative: (value) => parseFloat(value) >= 0 || `${i18n.t('maxValue', { max: 0 })}`
    },
    stepper: 1,
    expirationTypes: {
      date: false,
      km: false,
      hours: false
    },
    objectSelectorModel: {
      normalizer: (node) => {
        return {
          id: node.id,
          label: node.name,
          children: node.children
        }
      },
      data: null,
      selected: null,
      show: true,
      singleSelect: false,
      selectAll: false
    },
    typeSelectorModel: {
      normalizer: (node) => {
        return {
          id: node._id,
          label: node.name,
          children: node.children
        }
      },
      data: null,
      selected: null,
      show: true,
      singleSelect: false,
      selectAll: false
    },
    reminders: [],
    finalExpirationTypes: [],
    alertEmail: false,
    alertPush: false,
    alertEmails: null
  }),
  computed: {
    continueButtonText () {
      return this.stepper === 3 || this.edit ? this.$t('save') : this.$t('continue')
    },
    previousButtonText () {
      return this.stepper === 1 || this.edit ? this.$t('cancel') : this.$t('previous')
    },
    continueDisabled () {
      if (this.edit) {
        return !(this.editValidForm)
      } else if (this.stepper === 1) {
        return !((this.expirationTypes.date || this.expirationTypes.hours || this.expirationTypes.km) &&
        this.objectSelectorModel.selected && this.objectSelectorModel.selected.length > 0 &&
          this.typeSelectorModel.selected && this.typeSelectorModel.selected.length > 0)
      } else if (this.stepper === 3) {
        return !(this.validForm)
      } else if (this.stepper === 2) {
        return !(this.alertValidForm)
      }
    },
    showNote () {
      return this.editDto && this.editDto.expirations.find(e => e.parameter === 'hours' || e.parameter === 'km')
    }
  },
  created () {
    this.loadSelectors()
  },
  methods: {
    resetFields () {
      this.stepper = 1
      this.expirationTypes = {
        date: false,
        km: false,
        hours: false
      }
      this.reminders = []
      this.objectSelectorModel.data = []
      this.typeSelectorModel.data = []
      this.objectSelectorModel.selected = []
      this.typeSelectorModel.selected = []
      this.objectSelectorModel.selectAll = false
      this.typeSelectorModel.selectAll = false
      this.finalExpirationTypes = []
      this.alertEmail = false
      this.alertPush = false
      this.alertEmails = null
      this.alertValidForm = false
      this.loadSelectors()
    },
    cancel () {
      this.resetFields()
      this.$emit('closeDialog')
      if (this.$refs.alertValidForm) this.$refs.alertValidForm.resetValidation()
      if (this.$refs.editValidForm) this.$refs.editValidForm.resetValidation()
    },
    save () {
      this.$emit('saveDialog', { reminders: this.reminders, alertInfo: { push: this.alertPush, email: this.alertEmail, emails: this.alertEmails } })
      this.resetFields()
      if (this.$refs.alertValidForm) this.$refs.alertValidForm.resetValidation()
      if (this.$refs.editValidForm) this.$refs.editValidForm.resetValidation()
    },
    saveItem (item) {
      this.$emit('saveDialog', { reminders: [item], alertInfo: { push: this.alertPush, email: this.alertEmail, emails: this.alertEmails } }, true)
    },
    update () {
      this.$emit('saveDialog', this.editDto)
      this.resetFields()
    },
    async loadSelectors () {
      this.expirationTypes = { date: false, km: false, hours: false }
      this.allPeople = (await personApi.getAll()).data
      this.allThings = (await thingApi.getNested()).data
      this.objectSelectorModel.data = [{ id: 1, name: this.$t('reminder.people'), children: [] }, { id: 2, name: this.$t('reminder.things'), children: [] }, { id: 3, name: this.$t('reminder.enterprise'), children: [] }]
      this.allTypes = (await reminderApi.getAllTypesByEnterprises(this.enterprises.map(e => e.id))).filter(t => t.visible).concat((await reminderApi.getAllPlansByEnterprises(this.enterprises.map(e => e.id))).filter(t => t.visible))
      this.typeSelectorModel.data = []
    },
    filterTypesAndObjects () {
      let things = []
      let people = []
      let enterprises = []
      if (this.expirationTypes.date) {
        people = this.allPeople
        enterprises = this.enterprises
      }
      this.typeSelectorModel.data = this.allTypes.filter(t =>
        (this.expirationTypes.date && t.expiration.date.applies) ||
        (this.expirationTypes.hours && t.expiration.hours.applies) ||
        (this.expirationTypes.km && t.expiration.km.applies))
      things = this.allThings.filter(t => {
        if (t.children) {
          t.children = t.children.filter(t => this.expirationTypes.date ||
            (this.expirationTypes.hours && t.reminderBy && t.reminderBy.includes('hours')) ||
            (this.expirationTypes.km && t.reminderBy && t.reminderBy.includes('km')))
          return t.children.length > 0
        } else {
          return this.expirationTypes.date ||
          (this.expirationTypes.hours && t.reminderBy && t.reminderBy.includes('hours')) ||
          (this.expirationTypes.km && t.reminderBy && t.reminderBy.includes('km'))
        }
      })

      this.objectSelectorModel.data.find(d => d.id === 1).children = people
      this.objectSelectorModel.data.find(d => d.id === 2).children = things
      this.objectSelectorModel.data.find(d => d.id === 3).children = enterprises

      if (this.objectSelectorModel.selected) {
        this.objectSelectorModel.selected = this.objectSelectorModel.selected.filter(id => things.find(t => t.id === id) || people.find(t => t.id === id) || enterprises.find(t => t.id === id))
      }
      if (this.typeSelectorModel.selected) {
        this.typeSelectorModel.selected = this.typeSelectorModel.selected.filter(id => this.typeSelectorModel.data.find(t => t.id === id))
      }
    },
    async continueStepper () {
      if (this.edit) {
        this.update()
      } else {
        switch (this.stepper) {
          case 1:
            await this.generateValidReminders()
            this.stepper = 2
            break
          case 2:
            this.stepper = 3
            break
          case 3:
            this.save()
            break
        }
      }
    },
    async prevStepper () {
      if (this.edit) {
        this.cancel()
      } else {
        switch (this.stepper) {
          case 1:
            this.cancel()
            break
          case 2:
            this.stepper = 1
            this.reminders = []
            this.finalExpirationTypes = []
            break
          case 3:
            this.stepper = 2
            break
        }
      }
    },
    async getObjectsData (ids) {
      let objects = []
      let things = []
      ids.forEach(id => {
        const enterprise = this.enterprises.find(e => e.id === id)
        if (enterprise) {
          objects.push({ objectType: 'enterprise', object: enterprise })
        } else {
          const person = this.allPeople.find(t => t.id === id)
          if (person) {
            objects.push({ objectType: 'person', object: person })
          } else {
            this.allThings.some(t => {
              if (t.children) {
                const thing = t.children.find(t => t.id === id)
                if (thing) {
                  things.push(thing)
                  return true
                }
              } else if (t.id === id) {
                things.push(t)
                return true
              }
            })
          }
        }
      })
      if (things.length > 0) {
        // funcion para buscar los datos necesarios (horimetros, odometros, etc)
        things = (await reminderApi.getThingsReminderConfiguration(things)).data
        objects = objects.concat(things.map(t => ({ objectType: 'thing', object: t })))
      }
      return objects
    },
    getSelectedData (selectorData, selectedIds) {
      return selectorData.filter(function filterFunction (item) {
        if (selectedIds.includes(item.id)) return true
        if (item.children && item.children.length > 0) {
          return (item.children = item.children.filter(filterFunction)).length
        }
      })
    },
    async generateValidReminders () {
      this.reminders = []
      const selectedData = this.getSelectedData(this.objectSelectorModel.data, this.objectSelectorModel.selected)
      let objects = []
      getChildrenFromList(selectedData, objects)
      objects = await this.getObjectsData(objects.map(o => o.id))
      this.typeSelectorModel.selected.forEach(reminderTypeId => {
        let reminderType = this.allTypes.find(t => t._id === reminderTypeId)
        objects.forEach(object => {
          const expirations = this.getReminderExpirations(object, reminderType)
          if (expirations.length > 0) {
            this.reminders.push({
              object: object.object,
              objectType: object.objectType,
              reminderTypeName: reminderType.name,
              reminderTypeId: reminderType._id,
              expirations
            })
          }
        })
      })
    },
    getReminderExpirations (object, reminderType) {
      const expirations = []
      if (object.objectType === 'person') {
        if (this.expirationTypes.date && reminderType.expiration.date.applies) {
          if (!this.finalExpirationTypes.includes('date')) this.finalExpirationTypes.push('date')
          expirations.push({
            type: 'date',
            measure: 'days',
            repeatEvery: reminderType.expiration.date.repeatEvery,
            alertBefore: reminderType.expiration.date.alertBefore
          })
        }
      } else if (object.objectType === 'enterprise') {
        if (this.expirationTypes.date && reminderType.expiration.date.applies) {
          if ((reminderType.enterpriseId && reminderType.enterpriseId === object.object.id) ||
              (reminderType.default && !reminderType.invisibleTo.includes(object.object.id))) {
            if (!this.finalExpirationTypes.includes('date')) this.finalExpirationTypes.push('date')
            expirations.push({
              type: 'date',
              measure: 'days',
              repeatEvery: reminderType.expiration.date.repeatEvery,
              alertBefore: reminderType.expiration.date.alertBefore
            })
          }
        }
      } else {
        if ((reminderType.enterpriseId && reminderType.enterpriseId === object.object.enterpriseId) ||
            (reminderType.default && !reminderType.invisibleTo.includes(object.object.enterpriseId))) {
          if (this.expirationTypes.date && reminderType.expiration.date.applies) {
            if (!this.finalExpirationTypes.includes('date')) this.finalExpirationTypes.push('date')
            expirations.push({
              type: 'date',
              measure: 'days',
              repeatEvery: reminderType.expiration.date.repeatEvery,
              alertBefore: reminderType.expiration.date.alertBefore
            })
          }
          if (this.expirationTypes.km && reminderType.expiration.km.applies && object.object.reminderBy && object.object.reminderBy.includes('km')) {
            if (!this.finalExpirationTypes.includes('km')) this.finalExpirationTypes.push('km')
            expirations.push({
              type: 'km',
              measure: 'km',
              repeatEvery: reminderType.expiration.km.repeatEvery,
              alertBefore: reminderType.expiration.km.alertBefore,
              values: object.object.kmValues,
              selectedField: object.object.kmValues[0]
            })
          }
          if (this.expirationTypes.hours && reminderType.expiration.hours.applies && object.object.reminderBy && object.object.reminderBy.includes('hours')) {
            if (!this.finalExpirationTypes.includes('hours')) this.finalExpirationTypes.push('hours')
            expirations.push({
              type: 'hours',
              measure: 'hours',
              repeatEvery: reminderType.expiration.hours.repeatEvery,
              alertBefore: reminderType.expiration.hours.alertBefore,
              values: object.object.hoursValues,
              selectedField: object.object.hoursValues[0]
            })
          }
        }
      }
      return expirations
    },
    changeValidForm (validForm) {
      this.validForm = validForm
    },
    changeMovingHourType (expiration) {
      expiration.movingHourType = this.editDto.objectInfo.hoursValues.find(h => h.id === expiration.selectedHourType)
    },
    changeKmType (expiration) {
      expiration.kmType = this.editDto.objectInfo.kmValues.find(h => h.id === expiration.selectedKmType)
    }
  }
}
